import React from 'react';

import "./style/arquitetura.css";

const Arquitetura = () => {
    return (
        <div className='arquiteturamor'>
            <div className='arqtexto' data-aos="fade-right" data-aos-duration="1500" data-aos-delay="100">
                <div>
                    <a href="https://www.vgarquitetos.com.br/">
                 <h5 className='arqh'><p id='arqhp'>ARQUITETURA</p><br/> VINICIUS GUIMARÃES</h5>
                 </a>
                 </div>
                <p>
                         ELOS surge como um marco contemporâneo na Alameda Prudente de Moraes, uma das vias mais dinâmicas de Curitiba, conhecida pela vibrante cena cultural, gastronómica e social. O projeto propõe uma arquitetura que equilibra robustez e leveza, com uma materialidade sóbria destacada por elementos curvos que adicionam suavidade e elegância à volumetria. Concebido para atender às exigências da vida moderna, o ELOS combina funcionalidade e conforto em plantas versáteis, enquanto as áreas comuns integram trabalho, lazer e convivência. Um espaço que traduz praticidade e sofisticação, em perfeita sintonia com o pulsar urbano da cidade.              </p>
               
            </div>
            <div>
                <img src="./arquitetura.jpeg" className='arqimg' data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300"></img>
            </div>  
        </div>
    )
}

export default Arquitetura;