import { flexbox, height } from "@mui/system";
import React, {useState, useEffect} from "react";

import {useParams} from "react-router";

import { Link } from "react-router-dom";
import Header from "./Header";

import Card from "./Card";
import "./style/studios.css";
import image from "./style/1/1.jpg"
import image2 from "./style/2/1.jpg"
import image4 from "./style/4/1.jpg"
import image5 from "./style/5/1.jpg"
import imaged2 from "./style/6/1.jpg"
import imagesa from "./style/santi.jpeg"


function funcao() {
    if (
        document.querySelector('.navrespoim') &&
        document.querySelector('.botaoresp') &&
        document.querySelector('.fechar')
    ) {
        const objnav = document.querySelector('.navrespoim');
        const obj = document.querySelector('.botaoresp');
        obj.style.height = "50vh"
        obj.style.visibility = "visible"
        document.querySelector('.fechar').addEventListener("click", () => {
            obj.style.display = 'none'; 
        })
      
        obj.style.display = 'block';
      
        
    }
}

const Studios = () =>  {
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(data);
    const [loading, setLoading] = useState(false);
    let [test, setTest ] = useState(true);
    let componentMounted = true; 
 

    

  
   
    
    const Loading = () => {
        return (
            <>
                Carregando...
            </>
        )
    }

    const ShowStudios = () => {


        return (
           
        <div className="STUDIOS" >
            <div style={{flex:"row"}}>
            <div className="botaoresp" >
                <p className="fechar">X</p>
            <li>
                <a href="https://elosprudente.com.br" id="">página inicial</a></li>
                  
                    <li><a href="" id="">studios</a></li>
                 
                    <li><a href="https://elosprudente.com.br/#contato" id="">CONTATO</a></li>
            </div>
            <nav className="nav-bar-mor" id="home">
                <a onClick={funcao}className="navrespo"><img  className="navrespoim"src="./menu.png"></img></a>
                <ul className="nav-bar">
                    <li><a  href="https://elosprudente.com.br" id="style-1">início</a></li>
                    |
                    <li><a href="" id="style-1">STUDIOS</a></li>
                    |
                    <li><a href="https://elosprudente.com.br/#contato" id="style-1">CONTATO</a></li>
                </ul>
                <div className="progress-bar-mor">
                <div className="container">
                            <p>estágio da obra: 7%</p>
                            <div className="progress-bar"></div>
                        </div>
                </div>
            </nav>
           
            </div>
           
            <div className="studioG" style={{ margin: "auto", paddingTop:"10rem", paddingBottom:"6rem"}}>
  
            <Link to={"/studios/specific/1"} style={{ textDecoration: 'none' }}>
                                <Card 
                                image={image}
                                title={"STUDIOS FINAL 01"}
                                //201 | 301 | 401 | 501 | 601
                                location=""
                                description=""
                                //  {"Garden: " + (studio.temGarden ? "Sim |  " : "Não | ") 
                                //     +
                                //  "Churrasqueira: " + (studio.temChurrasqueira ? "Sim" : "Não")
                                // } 
                                studio={"ver studio"}
                                color={"2px solid #D9D9D9"}
                                textColor={"#D9D9D9"}
                                />
            </Link>
            <Link to={"/studios/specific/2"} style={{ textDecoration: 'none' }}>
                                <Card 
                                image={image2}
                                title={"STUDIOS FINAL 02 | 03 | 06 | 07 | 08"}
                                //STUDIOS 102 | 103 | 106 | 107  108 | 202 | 203 | 207  208 | 302 | 303 | 307  402 | 403 | 502 | 503
                                location=""
                                description=""
                                //  {"Garden: " + (studio.temGarden ? "Sim |  " : "Não | ") 
                                //     +
                                //  "Churrasqueira: " + (studio.temChurrasqueira ? "Sim" : "Não")
                                // } 
                                studio={"ver studio"}
                                color={"2px solid #D9D9D9"}
                                textColor={"#D9D9D9"}
                                />
            </Link>
            <Link to={"/studios/specific/4"} style={{ textDecoration: 'none' }}>
                                <Card 
                                image={image4}
                                title={"STUDIOS FINAL 04"}
                                location=""
                                description=""
                                //  {"Garden: " + (studio.temGarden ? "Sim |  " : "Não | ") 
                                //     +
                                //  "Churrasqueira: " + (studio.temChurrasqueira ? "Sim" : "Não")
                                // } 
                                studio={"ver studio"}
                                color={"2px solid #D9D9D9"}
                                textColor={"#D9D9D9"}
                                />
            </Link>
           
            <Link to={"/studios/specific/5"} style={{ textDecoration: 'none' }}>
                                <Card 
                                image={image5}
                                title={"STUDIO 105"}
                                location=""
                                description=""
                                //  {"Garden: " + (studio.temGarden ? "Sim |  " : "Não | ") 
                                //     +
                                //  "Churrasqueira: " + (studio.temChurrasqueira ? "Sim" : "Não")
                                // } 
                                studio={"ver studio"}
                                color={"2px solid #D9D9D9"}
                                textColor={"#D9D9D9"}
                                />
            </Link>
            <Link to={"/studios/specific/6"} style={{ textDecoration: 'none' }}>
                                <Card 
                                image={imaged2}
                                title={"DUPLEX 605"}
                                location=""
                                description=""
                                //  {"Garden: " + (studio.temGarden ? "Sim |  " : "Não | ") 
                                //     +
                                //  "Churrasqueira: " + (studio.temChurrasqueira ? "Sim" : "Não")
                                // } 
                                studio={"ver studio"}
                                color={"2px solid #D9D9D9"}
                                textColor={"#D9D9D9"}
                                />
            </Link>
           <a href="https://santistudioresidence.com.br">
                                <Card
                                image={imagesa}
                                title={"Confira nosso outro empreendimento"}
                                location=""
                                description=""
                                //  {"Garden: " + (studio.temGarden ? "Sim |  " : "Não | ") 
                                //     +
                                //  "Churrasqueira: " + (studio.temChurrasqueira ? "Sim" : "Não")
                                // } 
                                studio={"saiba mais"}
                                color={"2px solid #D9D9D9"}
                                textColor={"#D9D9D9"}
                            
                                />
            </a>
                             
                              
            </div>
        </div>
        )
    
    }

    return (
        <div style={{backgroundColor:"#D9D9D9", height:"100vh"}}>
            <ShowStudios/>
        </div>
    )
}
export default Studios;